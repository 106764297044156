
import {defineComponent} from "vue";
import Button from "@/components/UI/Button.vue";
import {CreateAccountCommand} from "@/models/user/CreateAccountCommand";
import {UserService} from "@/services/UserService";
import {UpsertOrganizationCommand} from "@/models/organizations/UpsertOrganizationCommand";
import {CreateProductionCommand} from "@/models/productions/CreateProductionCommand";
import {OrganizationService} from "@/services/OrganizationService";
import {ProductionService} from "@/services/ProductionService";
import Selector2 from "@/components/inputs/SSelect2.vue";
import useVuelidate from "@vuelidate/core";
import {email, required, requiredIf, url} from "@vuelidate/validators";
import {PrimaryIndustry} from "@/models/enums/PrimaryIndustry";
import {UserType} from "@/models/enums/UserType";
import {GenderIdentity} from "@/models/enums/GenderIdentity";
import posthog from "posthog-js";
import Bugsnag from "@bugsnag/js";
import {ElNotification} from "element-plus";
import {useUser} from "@/store/pinia/userStore";
import moment from "moment";
import {WebAnalytics} from "@/modules/webAnalytics";
import {auth0} from "@/main";

export default defineComponent({
  name: "CreateProfile",
  setup() {
    const userStore = useUser()
    return {
      v$: useVuelidate(),
      userStore
    }
  },
  components: {Selector2, Button},
  data() {
    return {
      currentStep: 1,
      isLoading: false,
      userPhone: '',
      orgPhone: '',
      initialPersona: 'personal',
      consent: {
        acceptsPrivacyPolicy: false,
        acceptsTos: false,
      },
      user: {
        initialPersona: 1,
        phoneReceiveTexts: true,
        firstName: '',
        phoneNumber: '',
        primaryEmail: '',
        birthdate: '',
        title: '',
        lastName: '',
        industry: PrimaryIndustry.NonUnion,
        type: UserType.IndividualPerformance,
        genderIdentity: GenderIdentity.Woman,
        pronouns: '',
        emailOptIn: true
      } as CreateAccountCommand,
      organization: {} as UpsertOrganizationCommand,
      production: {} as CreateProductionCommand,
      genderOptions: [
        {
          text: 'Prefer Not To Say',
          id: 0
        },
        {
          text: 'Non-Binary',
          id: 1
        },
        {
          text: 'Man',
          id: 2
        },
        {
          text: 'Woman',
          id: 3
        }
      ],
      userPersonaOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Individual (Performance)',
          id: 1
        },
        {
          text: 'Individual (Crew)',
          id: 2
        },
        {
          text: 'Individual (Production Team)',
          id: 3
        },
        {
          text: 'Student',
          id: 4
        },
        {
          text: 'Educator',
          id: 5
        },
        {
          text: 'Producer',
          id: 6
        },

      ],
      primaryIndustryOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Union',
          id: 1
        },
        {
          text: 'Non-Union',
          id: 2
        },
        {
          text: 'Education',
          id: 3
        },
        {
          text: 'Dance',
          id: 4
        },
        {
          text: 'Choral',
          id: 5
        }
      ],
      organizationIndustryOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Professional Theatre',
          id: 1
        },
        {
          text: 'Community Theatre',
          id: 2
        },
        {
          text: 'Education',
          id: 3
        },
        {
          text: 'Dance',
          id: 4
        },
        {
          text: 'Choral',
          id: 5
        },
        {
          text: 'Tour',
          id: 6
        },
        {
          text: 'Individual',
          id: 7
        }
      ],
      productionTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Musical',
          id: 1
        },
        {
          text: 'Play',
          id: 2
        },
        {
          text: 'Opera',
          id: 3
        },
        {
          text: 'Dance',
          id: 4
        },
        {
          text: 'Concert',
          id: 5
        }
      ],
      hasOrgCreated: false,
      hasProdCreated: false,
      userEmail: '',
      userStageCode: '',
      productionStageCode: '',
      organizationStageCode: ''
    }
  },
  validations() {
    return {
      user: {
        firstName: {required},
        lastName: {required},
        birthdate: {required, validDate: validBirthdate()},
        pronouns: {required},
        phoneNumber: {requiredIf: requiredIf(this.authConnectionType === 'email')},
        primaryEmail:  {requiredIf: requiredIf(this.authConnectionType === 'sms')},
      },
      organization: {
        name: {required},
        email: {email},
        website: {url},
      },
      production: {
        name: {required}
      },
    }
  },
  computed: {
    termsValid(): boolean {
      return this.consent.acceptsPrivacyPolicy && this.consent.acceptsTos
    },
    authConnectionType(): string {
      return auth0.user.value.sub?.split('|')[0] || ''
    }
  },
  methods: {
    goHome() {
      this.userStore.fetchUser()
      this.$router.push({name: 'onboarding'})
    },
    previousStep() {
      this.currentStep--
    },
    nextStep() {
      if (this.currentStep === 1) {
        this.currentStep++
      } else if (this.currentStep === 2) {
        this.v$.user.$touch()
        if (!this.v$.user.$invalid && this.termsValid) {
          this.currentStep++
        }
      } else if (this.currentStep === 3) {
        this.v$.organization.$touch()
        if (!this.v$.organization.$invalid) {
          this.currentStep++
        }
      }
    },
    signUp() {
      if (this.currentStep === 2) {
        this.v$.user.$touch()
        if (!this.v$.user.$invalid && this.termsValid) {
          this.submitUser();
        } else {
          return;
        }
      } else if (this.currentStep === 3) {
        this.v$.organization.$touch()
        if (!this.v$.organization.$invalid) {
          this.submitUser();
        } else {
          return;
        }
      } else if (this.currentStep === 4) {
        this.v$.production.$touch()
        if (!this.v$.production.$invalid) {
          this.submitUser();
        } else {
          return;
        }
      }
    },
    submitUser() {
      this.isLoading = true
      //console.log('simulate entering creation phase')
      // //Create user
      this.user.initialPersona = this.initialPersona === 'personal' ? 1 : 2
      UserService.create(this.user).then(response => {
        if (this.currentStep === 4) {
          this.hasOrgCreated = true
          this.hasProdCreated = true
        }
        this.userStageCode = response.data.stageCode
        //Then create organization if applicable
        if (this.hasOrgCreated) {
          this.submitOrganization()
        } else {
          //Then move to the final step
          this.currentStep = 5
          this.isLoading = false
        }
      }).catch((err) => {
        Bugsnag.notify(err, function (event) {
          event.severity = "error"
          event.context = "Sign Up: Submit User"
        })
        posthog.capture('Exception', {process: 'Sign Up: Submit User'})
        ElNotification({
          title: 'Error',
          message: 'Something went wrong while setting up your account.',
          type: 'error',
        })
        this.isLoading = false
      }).finally(() => {
        WebAnalytics.trackFlexible('Signed Up', {
          initialPersona: this.initialPersona,
          initialAuthType: this.authConnectionType
        })
      })
    },
    submitOrganization() {
      OrganizationService.create(this.organization).then(response => {
        this.organizationStageCode = response.data.stageCode
        //Then create production if applicable
        if (this.hasProdCreated) {
          this.production.organizationId = response.data.organizationId
          this.submitProduction(response.data.organizationId)
        } else {
          //Then move to the final step
          this.currentStep = 5
          this.isLoading = false
        }
      }).catch((err) => {
        Bugsnag.notify(err, function (event) {
          event.severity = "error"
          event.context = "Sign Up: Submit Organization"
        })
        posthog.capture('Exception', {process: 'Sign Up: Submit Organization'})
        ElNotification({
          title: 'Error',
          message: 'Something went wrong while setting up your organization.',
          type: 'error',
        })
        this.currentStep = 5
        this.isLoading = false
      }).finally(() => {
        WebAnalytics.trackFlexible('Created Organization', {
          withSignup: true
        })
      })
    },
    submitProduction(organizationId: string) {
      ProductionService.create(this.production, organizationId).then(response => {
        this.productionStageCode = response.data.stageCode
        //Then move to the final step
        this.currentStep = 5
        this.isLoading = false
      }).catch((err) => {
        Bugsnag.notify(err, function (event) {
          event.severity = "error"
          event.context = "Sign Up: Submit Production"
        })
        posthog.capture('Exception', {process: 'Sign Up: Submit Production'})
        ElNotification({
          title: 'Error',
          message: 'Something went wrong while setting up your production.',
          type: 'error',
        })
        this.currentStep = 5
        this.isLoading = false
      }).finally(() => {
        WebAnalytics.trackFlexible('Created Production', {
          withSignup: true
        })
      })
    }
  },
})
const validBirthdate = () => (birthdate) => {
  const bday = moment(birthdate);
  const yearsOld = moment().diff(bday, 'years');
  return yearsOld >= 13 && yearsOld < 100;
};
